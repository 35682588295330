@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'dm_sansbold';
    src: url('../public/fonts/dmsans/DMSans-Bold.woff2') format('woff2'),
    url('../public/fonts/dmsans/DMSans-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'dm_sansregular';
    src: url('../public/fonts/dmsans/DMSans-Regular.woff2') format('woff2'),
    url('../public/fonts/dmsans/DMSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'dm_sansmedium';
    src: url('../public/fonts/dmsans/DMSans-Medium.woff2') format('woff2'),
    url('../public/fonts/dmsans/DMSans-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
}

/* button styles */
.btn-primary {
  @apply bg-gradient-to-br from-[#FCEE21] to-[#F7931E] py-1 px-3 text-black rounded-[4px] font-semibold shadow-md hover:opacity-75;
}

.btn-primary-disable {
  @apply bg-gradient-to-tl bg-[#c2c6cc] py-1 px-3 text-white rounded-[4px] font-semibold shadow-md hover:opacity-75;
}

.btn-primary-rounded-less {
  @apply py-1 px-3 bg-gradient-to-br from-[#FCEE21] to-[#F7931E] text-black font-semibold rounded-[4px] shadow-md hover:opacity-75 focus:outline-none;
}

.btn-primary-rounded-less-disable {
  @apply py-1 px-3 bg-[#c2c6cc] text-white font-semibold rounded-[4px] shadow-md hover:bg-[#c2c6cc]/[0.7] focus:outline-none;
}

.btn-primary-rounded {
  @apply py-1 px-3 bg-gradient-to-br bg-[#c2c6cc] text-white font-semibold rounded-[14px] shadow-md hover:bg-[#F7931E]/[0.7] focus:outline-none;
}

.btn-primary-rounded-disable {
  @apply py-1 px-3 bg-[#c2c6cc] text-white font-semibold rounded-[14px] shadow-md hover:bg-[#c2c6cc]/[0.7] focus:outline-none;
}

.btn-error {
  @apply py-1 px-3 bg-[#e34242] text-white font-semibold shadow-md hover:bg-[#e34242]/[0.7] focus:outline-none;
}

.btn-primary-text {
  @apply py-1 px-3 bg-transparent text-[#F7A21E] font-semibold hover:text-[#F7A21E]/[0.7] focus:outline-none;
}

.btn-primary-text-underline {
  @apply inset-0 bg-transparent text-[#F7A21E] underline focus:outline-none hover:opacity-75 font-semibold py-1 px-3;
}

.btn-primary-text-underline-disable {
  @apply py-1 px-3 underline bg-transparent text-[#c2c6cc] font-semibold hover:opacity-75 focus:outline-none;
}

.btn-green-gradient {
  @apply bg-gradient-to-tl from-[#7FB0D7] to-[#F7931E] py-1 px-3 text-white rounded-[4px] font-semibold shadow-md hover:opacity-75;
}

.btn-green-gradient-disable {
  @apply bg-gradient-to-tl bg-[#c2c6cc] py-1 px-3 text-white rounded-[4px] font-semibold shadow-md hover:opacity-75;
}

.btn-filters {
  @apply flex flex-row justify-evenly items-center py-1 px-3 bg-gradient-to-br from-[#FCEE21] to-[#F7931E] rounded-[14px] text-black font-semibold hover:opacity-75 focus:outline-none;
}

.btn-noactive-filters {
  @apply flex flex-row justify-evenly items-center py-1 px-3 bg-[#f9fbfc] rounded-[14px] border border-solid border-[#3c3c434d] rounded-[14px] text-[#3c3c434d] font-semibold hover:opacity-75 focus:outline-none;
}

.btn-categories {
  @apply flex flex-row justify-evenly items-center py-1 px-3 bg-white text-[#F7A21E] font-semibold rounded-[14px] hover:bg-[#fafafa]/[0.7] focus:outline-none;
}

.btn-noactive-categories {
  @apply flex flex-row justify-evenly items-center py-1 px-3 bg-[#f7f9fb] rounded-[14px] text-[#c2c6cc] font-semibold hover:text-[#a4a4a4]/[0.9] focus:outline-none;
}

.btn-categories-list {
  @apply flex flex-row justify-start gap-2 items-center py-1 px-3 bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !text-[#202020] font-semibold rounded-[14px] hover:opacity-75 focus:outline-none;
}

.btn-noactive-categories-list {
  @apply flex flex-row justify-start gap-2 items-center py-1 px-3 bg-[#f7f9fb] rounded-[14px] text-[#c2c6cc] font-semibold hover:text-[#a4a4a4]/[0.9] focus:outline-none;
}

.btn-main {
  @apply bg-[#ebedef] font-semibold text-[#202020] py-1 px-3 rounded-[8px] border border-[#ebedef] hover:border-black;
}

.btn-main-disable {
  @apply bg-[#d9d9d9] font-semibold text-[#8a8a8e] py-1 px-3 rounded-[8px];
}

.btn-orange-rounded-less {
  @apply bg-gradient-to-br from-[#FCEE21] to-[#F7931E] font-semibold text-black py-1 px-3 hover:opacity-75 hover:bg-[#f7a21e] rounded-[4px];
}

.btn-orange-rounded-disable {
  @apply py-1 px-3 bg-[#c2c6cc] text-white font-semibold rounded-[4px] shadow-md hover:bg-[#c2c6cc]/[0.7] focus:outline-none;
}

.btn-orange-faded-rounded-less {
  @apply bg-[#dad2c4] font-semibold text-[#f7a21e] py-1 px-3 hover:opacity-75 rounded-[4px];
}

/* Default Texts, Heading */
.page-title {
  @apply text-[#464E5F] font-dm_sansbold text-4xl mb-3 mt-1;
}
.heading-one {
  @apply text-black font-dm_sansbold text-8xl mb-3 mt-1;
}
.heading-two {
  @apply text-black font-dm_sansbold text-7xl mb-3 mt-1;
}
.heading-three {
  @apply text-black font-dm_sansbold text-6xl mb-3 mt-1;
}
.heading-four {
  @apply text-black font-dm_sansbold text-5xl mb-3 mt-1;
}

/* Select */
.select-primary {
  @apply bg-[#FFFFFF] w-full py-2 pl-1 pr-2 border border-solid border-[#e5e7eb] rounded-[5px] focus:outline-none;
}

.select-primary-error {
  @apply bg-[#FFFFFF] w-full py-2 pl-1 pr-2 border border-solid border-red-400 rounded-[5px] focus:outline-none;
}

.select-placeholder {
  @apply text-slate-300;
}
select:invalid,
select option[value=''] {
  color: #999999 !important;
}

/* textarea */
.textarea-primary {
  @apply bg-[#F3F3F3] px-2 py-3 w-full placeholder:text-[#9E9E9E] resize-none focus:outline-none;
}

.speech-bubble-admin {
	position: relative;
	background: #f3f3f9;
	border-radius: .4em;
}

.speech-bubble-admin:after {
	content: '';
	position: absolute;
	right: 0;
	top: 0%;
	width: 0;
	height: 0;
	border: 15px solid transparent;
	border-left-color: #f3f3f9;
	border-right: 0;
	border-top: 0;
	margin-top: 0px;
	margin-right: -10px;
}

.speech-bubble-user {
	position: relative;
	background: #F7A21E;
	border-radius: .4em;
}

.speech-bubble-user:after {
	content: '';
	position: absolute;
	left: 0;
	top: 0%;
	width: 0;
	height: 0;
	border: 15px solid transparent;
	border-right-color: #F7A21E;
	border-left: 0;
	border-top: 0;
	margin-top: 0px;
	margin-left: -10px;
}

@keyframes shine-lines-table {
  0% {
	opacity:0.3;
  }
  40%{
	opacity:0.6;
  }
  100% {
	opacity:0.3;
  }
}

.td-1 span {
  width: calc(100% - 35px);
  height: 15px;
  border-radius: 20px;
  background-image: linear-gradient(90deg, #e4e4e4 0px, #e8e8e8 40px, #e4e4e4 80px);
  background-size: 300px;
  animation: shine-lines-table 1.6s infinite linear;
  display: inline-block;
  min-width:15px;
}
